import { jsx } from '@emotion/react'
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react' // eslint-disable-line
import tw from 'twin.macro'
import { Button } from './components'
import { Modal } from './components'
import queryString from 'query-string'
import verify from './utils/verify-registration'
import register from './utils/register'
/*
http://localhost:3000/#
access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjE5MDkzMzA4LCJzdWIiOiJmNjhkZjExMS1jM2ZkLTRiZWUtOGNlZS04MTNjODBlNGZkMzYiLCJlbWFpbCI6ImFwaSt0ZXN0XzJAc3Vub2JpYXBwLmNvbSIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6ImVtYWlsIn0sInVzZXJfbWV0YWRhdGEiOnt9LCJyb2xlIjoiYXV0aGVudGljYXRlZCJ9.Qegphk2WwUQb-3zfpb23GEjG4xDUl9swZt_W-g9ILr4&expires_in=3600&refresh_token=VTgkbdJEm7IDWowj93UJ5w&token_type=bearer&type=invite
*/

const App = () => {
  const [modal, setModal] = useState(false)
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [repDetails, setRepDetails] = useState({})
  const [verificationError, setVerificationError] = useState('wait')
  const [registering, setRegistering] = useState(false)
  const [completeError, setCompleteError] = useState('wait')

  // Parses out the parameters and sets as state
  useEffect(() => {
    async function startUp() {
      const {
        id = false,
        email = false,
        code = false,
      } = queryString.parse(location.search)
      if (!id || !email || !code) {
        alert(
          'Please contact Sunobi service at service@sunobiapp.com. Your registration link is invalid',
        )
        return
      }

      let parsedID = parseInt(id)
      let parsedCode = parseInt(code)
      if (parsedID == NaN || parsedCode == NaN) {
        alert(
          'Please contact Sunobi service at service@sunobiapp.com. Your registration link is invalid',
        )
        return
      }

      const body = { id: parsedID, email, code: parsedCode }
      setRepDetails(body)
      const res = await verify(body)
      setVerificationError(res)
    }

    startUp()
  }, [])

  const isValid = val => {
    if (val.length == 0) return true
    if (val.length >= 8 && /\d/g.test(val) && /[A-Za-z]/g.test(val)) {
      return true
    }
    return false
  }

  const submitForm = async event => {
    event.preventDefault()
    if (isValid(password) && confirm == password) {
      setRegistering(true)
      try {
        const res = await register({
          ...repDetails,
          password,
        })

        setRegistering(false)
        setCompleteError(res)
      } catch (err) {
        setRegistering(false)
        setCompleteError('Registering has failed. Please re-attempt')
      }
    } else {
      alert('Please fill out your password')
    }
  }

  const isVerified =
    verificationError.length == 0 && verificationError != 'wait'
  const isCompleted = completeError.length == 0

  console.log({ completeError, isCompleted })
  return (
    <div
      css={[
        tw`flex flex-col items-center justify-center`,
        'background-color: #222; min-height: 100vh;',
      ]}
    >
      <div
        css={[
          tw`flex flex-col justify-center my-10`,
          'max-width: 90vw; width: 300px;',
        ]}
      >
        <iframe
          src="https://customer-dev.sunobiapp.com/hype-embed.html"
          style={{ width: '300px', height: '300px' }}
        />
        {isCompleted ? (
          <h2
            tw={'text-base text-white  uppercase text-center tracking-widest'}
          >
            Your account is now registered
          </h2>
        ) : (
          <h2
            tw={'text-base text-white  uppercase text-center tracking-widest'}
          >
            {verificationError.length > 0 && verificationError != 'wait'
              ? verificationError
              : completeError.length > 0 && completeError != 'wait'
              ? completeError
              : registering
              ? 'Please wait while we register your account'
              : verificationError == 'wait'
              ? 'Verifying. Please Wait'
              : 'Create passsword'}
          </h2>
        )}

        {isVerified && !registering && !isCompleted ? (
          <>
            <label tw="block  my-3">
              <input
                type="password"
                css={[
                  tw`mt-1 block w-full p-2 rounded-md border focus:outline-none text-sm lowercase`,
                  !isValid(password) && 'border-color: red',
                ]}
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              {!isValid(password) && (
                <p tw={'text-xs text-white pt-1 pl-1'}>
                  Minimum length of 8. Include both letters and numbers.
                </p>
              )}
            </label>
            <label tw="block my-3">
              <input
                type="password"
                css={[
                  tw`mt-1 block w-full p-2 rounded-md border focus:outline-none text-sm lowercase `,
                  isValid(password) &&
                    confirm != password &&
                    'border-color: red',
                ]}
                placeholder="Password Confirm"
                value={confirm}
                onChange={e => setConfirm(e.target.value)}
              />
              {isValid(password) && confirm != password && (
                <p tw={'text-xs text-white pt-1 pl-1'}>
                  Password confirmation must match
                </p>
              )}
            </label>
            <Button
              variant={
                isValid(password) && confirm == password
                  ? 'primary'
                  : 'not-active'
              }
              onClick={event => submitForm(event)}
            >
              Submit
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
      <Button variant="modal" onClick={() => setModal(true)}>
        Help
      </Button>
      {modal ? (
        <Modal closeFn={() => setModal(false)} location={location} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default App
