import axios from 'axios'
import { baseurl } from './const'

const url = baseurl + 'identity/register'

const helpString = 'Please contact sunobi Service via the help button'
export default async body => {
    const headers = {
        'Content-Type': 'application/json',
    }
    try {
        const res = await axios.post(url, body, { headers })
        if (!res.data.hasError) {
            return ''
        } else {
            return `Registration failed. ${helpString}`
        }
    } catch (err) {
        // Temp pretend that it has worked
        // return ''
        return `Please try again. ${helpString}`
    }
}