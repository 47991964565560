import tw, { styled, css, theme } from 'twin.macro'

const Button = styled.button(({ variant, isSmall, withinModal = false }) => [
  // The common button styles added with the tw import
  tw`py-2 rounded focus:outline-none transform duration-75 text-sm font-medium uppercase my-3`,

  // Use the variant grouping feature to add variants to multiple classes

  // Use props to conditionally style your components
  variant === 'not-active' && tw`bg-red-400 text-white border-black`,
  variant === 'primary' &&
    tw`bg-green-400 text-white border-black hocus:(bg-green-500)`,

  variant === 'modal' &&
    tw`bg-blue-400 text-white border-black hocus:(bg-blue-500) px-5 absolute right-5 top-3`,
  // The theme import can supply values from your tailwind.config.js
  css`
    color: ${theme`colors.white`};
  `,
  withinModal &&
    css`
      width: 48%;
    `,
  withinModal && variant === 'not-active' && tw`hocus:(bg-red-500)`,
])

export default Button
