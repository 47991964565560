import React from 'react'
import ReactDOM from 'react-dom'
import GlobalStyles from './styles/GlobalStyles'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'

import Registration from './Registration'

// This is legit https://identity.sunobiapp.com/#access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjQ0ODg5MzkxLCJzdWIiOiJkOTI1ZDU2My04NGFjLTQ4ZTAtYjY3MC02NDY1MmRiNDY0NTAiLCJlbWFpbCI6ImRlditsb2NhbF92MkBzdW5vYmlhcHAuY29tIiwicGhvbmUiOiIiLCJhcHBfbWV0YWRhdGEiOnsicHJvdmlkZXIiOiJlbWFpbCIsInByb3ZpZGVycyI6WyJlbWFpbCJdfSwidXNlcl9tZXRhZGF0YSI6e30sInJvbGUiOiJhdXRoZW50aWNhdGVkIn0.9QB4kWdKLG64rAhALYufFYSpxPCePGlMUwzKXB2U48E&expires_in=604800&refresh_token=68R0v6zDT3uyX2oahTv3oQ&token_type=bearer&type=invite

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/v2/register/" element={<Registration />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)
