import axios from 'axios'
import { baseurl } from './const'

const url = baseurl + 'identity/reset-password'

export default async({ password, access_token }) => {
    const body = { password }
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
    }
    try {
        const response = await axios.post(url, body, { headers })
        if (!response.data.hasError) {
            return response
        } else {
            alert('There has been an error, please contact Sunobi support.')
            return false
        }
    } catch (err) {
        alert('There has been an error, please contact Sunobi support.')
        return false
    }
}