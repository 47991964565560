import { jsx } from '@emotion/react'
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react' // eslint-disable-line
import tw, { styled, css, theme } from 'twin.macro'
import axios from 'axios'
import { Button } from './index'
import queryString from 'query-string'

const Wrapper = tw.a`absolute w-full min-h-full bg-black bg-opacity-30 text-white top-0 left-0 flex justify-center items-center md:items-start md:justify-end  cursor-pointer`

const Box = styled.div(() => [
  tw`bg-white rounded w-10/12 cursor-default text-black px-3 pt-4 md:mt-5 md:mr-5`,
  css`
    max-width: 300px;
    min-height: 300px;
  `,
])

/*
This is a recovery
-------
#access_token=blahblah
&expires_in=604800
&refresh_token=e4uH48mleJPwR8hhSQ2gOg
&token_type=bearer
&type=recovery
=======

This is an invite
-------
#access_token=blahBlah
&expires_in=604800
&refresh_token=wlcQmBAbB6-hyxfMlMpAeg
&token_type=bearer
&type=invite
=======

This is a magicLink
-------
#access_token=blahblah
&expires_in=604800
&refresh_token=2MWmmPDVMnzWlqV8fx_Nsg
&token_type=bearer
&type=magiclink
=======
*/
export default ({ closeFn, location }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [href, setHref] = useState('')
  const [type, setType] = useState('')
  const [repDetails, setRepDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    if (!location) return

    console.log({ location })

    if (location.href) setHref(location.href)
    const isNew =
      location.search && location.pathname && location.pathname == '/v2/'

    if (isNew) {
      const {
        id = false,
        email = false,
        code = false,
      } = queryString.parse(location.search)
      if (!id || !email || !code) {
        return
      }
      setType('v2')
      setRepDetails({ id, email, code })
      setEmail(email)
      return
    }
    // Fallback to version 1
    if (location.hash) {
      const params = queryString.parse(location.hash)
      if (params.type) setType(params.type)
    }
  }, [])

  const submitForm = async () => {
    if (!email || !name || !message) {
      alert('Please fill out all fields')
      return
    }

    setLoading(true)

    const summary = `Sunobi Registration ${type.toUpperCase()} issue from ${name}`

    let description = `
        Name:   ${name}
        Email:  ${email}
        Href:   ${href}
        Type: ${type}

    `

    if (repDetails && repDetails.id && type == 'v2') {
      const qbUrl = `https://kota.quickbase.com/db/bp3gc8t62?a=dr&rid=${repDetails.id}`
      description += `  
        Message:
        ${message}

        CRM:
        ${qbUrl}
        `
    } else {
      description += `
        Message:
        ${message}
        `
    }

    const errMessage =
      'There was an error please try again. If this continues please email service@sunobiapp.com'
    const url = `https://api-internal.sunobiapp.com/service/`
    const body = {
      email,
      requestTypeId: 1,
      summary,
      description,
    }
    try {
      const res = await axios.post(url, body)
      if (res.status == 200) {
        setLoading(false)
        setName('')
        setEmail('')
        setMessage('')
        setCompleted(true)
        setTimeout(closeFn, 5000)
      } else {
        setLoading(false)
        alert(errMessage)
        setCompleted(false)
      }
    } catch (err) {
      alert(errMessage)
      setLoading(false)
      setCompleted(false)
    }
  }
  return (
    <Wrapper onClick={closeFn}>
      <Box
        onClick={event => {
          event.stopPropagation()
        }}
      >
        <h3 tw={'text-base uppercase text-center tracking-widest'}>
          Contact Sunobi Service
        </h3>
        {completed ? (
          <p tw={'text-base text-center pt-12'}>
            Thank you for your support request we will get back to you as soon
            as possible.
          </p>
        ) : loading ? (
          <p tw={'text-sm text-center pt-12'}>
            We are sending request. Please wait for confirmation
          </p>
        ) : (
          <>
            <p tw={'text-sm text-center pt-1'}>
              This form is the quickest way of helping us solve your login
              issues.
            </p>
            <input
              type="text"
              css={[
                tw`mt-3 block w-full p-2 rounded-md border focus:outline-none text-sm`,
              ]}
              placeholder="Name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <input
              type="email"
              css={[
                tw`mt-3 block w-full p-2 rounded-md border focus:outline-none text-sm lowercase`,
              ]}
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <textarea
              css={[
                tw`mt-3 block w-full p-2 rounded-md border focus:outline-none text-sm `,
                css`
                  min-height: 150px;
                `,
              ]}
              placeholder="Message"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
            <div tw="flex justify-between">
              <Button variant="primary" withinModal={true} onClick={submitForm}>
                Submit
              </Button>
              <Button variant="not-active" withinModal={true} onClick={closeFn}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Box>
    </Wrapper>
  )
}
