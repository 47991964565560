import { jsx } from '@emotion/react'
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react' // eslint-disable-line
import tw from 'twin.macro'
import { Button } from './components'
import { Modal } from './components'
import queryString from 'query-string'
import network from './utils/network'
/*
http://localhost:3000/#
access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjE5MDkzMzA4LCJzdWIiOiJmNjhkZjExMS1jM2ZkLTRiZWUtOGNlZS04MTNjODBlNGZkMzYiLCJlbWFpbCI6ImFwaSt0ZXN0XzJAc3Vub2JpYXBwLmNvbSIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6ImVtYWlsIn0sInVzZXJfbWV0YWRhdGEiOnt9LCJyb2xlIjoiYXV0aGVudGljYXRlZCJ9.Qegphk2WwUQb-3zfpb23GEjG4xDUl9swZt_W-g9ILr4&expires_in=3600&refresh_token=VTgkbdJEm7IDWowj93UJ5w&token_type=bearer&type=invite
*/

const App = () => {
  const [modal, setModal] = useState(false)
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [type, setType] = useState('')
  const [access_token, setAccessToken] = useState('')
  const [message, setMessage] = useState(false)
  // Example object shape
  /*
  {
    hasError: true,
    errorMessage: 'Unauthorized: Invalid token: token is expired by 7h26m52s',
    status: 401,
    authz: null,
  }
  */

  // Parses out the parameters and sets as state
  useEffect(() => {
    const { type, access_token } = queryString.parse(location.hash)
    if (type && access_token) {
      setType(type)
      setAccessToken(access_token)
    } else {
      alert('There is something wrong with your link')
    }
  }, [])

  const isValid = val => {
    if (val.length == 0) return true
    if (val.length >= 8 && /\d/g.test(val) && /[A-Za-z]/g.test(val)) {
      return true
    }
    return false
  }

  const submitForm = async event => {
    event.preventDefault()
    if (isValid(password) && confirm == password) {
      const res = await network({
        access_token,
        password,
      })
      // Who cares about type safety
      if (res) {
        // While devving we will save the entire object
        setMessage(res)
      }
    } else {
      alert('Please fill out your password')
    }
  }

  console.log({ type })
  return (
    <div
      css={[
        tw`flex flex-col items-center justify-center`,
        'background-color: #222; min-height: 100vh;',
      ]}
    >
      <div
        css={[
          tw`flex flex-col justify-center my-10`,
          type && type == 'invite' && tw`items-center`,
          'max-width: 90vw, width: 300px;',
        ]}
      >
        <iframe
          src="https://customer-dev.sunobiapp.com/hype-embed.html"
          style={{ width: '300px', height: '300px' }}
        />

        <h2 tw={'text-base text-white  uppercase text-center tracking-widest'}>
          {type && access_token
            ? message
              ? `You can now login`
              : type == 'invite'
              ? `Sorry Old Registration no longer works`
              : 'Reset Password'
            : 'Please contact Sunobi Support'}
        </h2>

        {!message && type && type != 'invite' && access_token && (
          <>
            <label tw="block  my-3">
              <input
                type="password"
                css={[
                  tw`mt-1 block w-full p-2 rounded-md border focus:outline-none text-sm lowercase`,
                  !isValid(password) && 'border-color: red',
                ]}
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              {!isValid(password) && (
                <p tw={'text-xs text-white pt-1 pl-1'}>
                  Minimum length of 8. Include both letters and numbers.
                </p>
              )}
            </label>
            <label tw="block my-3">
              <input
                type="password"
                css={[
                  tw`mt-1 block w-full p-2 rounded-md border focus:outline-none text-sm lowercase `,
                  isValid(password) &&
                    confirm != password &&
                    'border-color: red',
                ]}
                placeholder="Password Confirm"
                value={confirm}
                onChange={e => setConfirm(e.target.value)}
              />
              {isValid(password) && confirm != password && (
                <p tw={'text-xs text-white pt-1 pl-1'}>
                  Password confirmation must match
                </p>
              )}
            </label>
            <Button
              variant={
                isValid(password) && confirm == password
                  ? 'primary'
                  : 'not-active'
              }
              onClick={event => submitForm(event)}
            >
              Submit
            </Button>
          </>
        )}

        {message && (
          <p
            tw={
              'text-sm text-white pt-5 leading-relaxed tracking-wider text-center'
            }
          >
            Please open Sunobi and login using your <br />
            email and the password you have just set.
          </p>
        )}
        {type && type == 'invite' ? (
          <p
            tw={
              'text-sm text-white pt-5 leading-relaxed tracking-wider text-center'
            }
          >
            Please create a ticket via help and Sunobi service will get you
            <br />a new invite. Our new invites do not expire!
          </p>
        ) : (
          <></>
        )}
      </div>

      <Button variant="modal" onClick={() => setModal(true)}>
        Help
      </Button>
      {modal ? (
        <Modal closeFn={() => setModal(false)} location={location} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default App
