import { Global, css } from '@emotion/react'
import tw, { theme, GlobalStyles as BaseStyles } from 'twin.macro'

const customStyles = css`
  body {
    -webkit-tap-highlight-color: ${theme`colors.purple.500`};
    ${tw`antialiased`}
  }
  body {
    background: #fff;
    width: 100vw;
    font-family: 'Roboto', sans-serif;
  }
  /* Carl you are silly, work out correct global loading here when have time please */
  body p,
  body h1,
  body h2,
  body h3,
  body h4,
  body h5,
  body li,
  body div,
  body input,
  body span {
    font-family: 'Roboto', sans-serif;
  }
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
)

export default GlobalStyles
