import axios from 'axios'
import { baseurl, registrationStatuses } from './const'

const url = baseurl + 'identity/verify-registration'

const helpString = 'Please contact Sunobi Service via the help button.'
export default async body => {
    const headers = {
        'Content-Type': 'application/json',
    }
    try {
        const res = await axios.post(url, body, { headers })
        if (!res.data.hasError) {
            return ''
        } else {

        }
    } catch (err) {
        let httpStatus = err.request.status
        if (httpStatus === 400) {
            let status = err.response.data.status
            if (status === registrationStatuses.alreadyRegistered) {
                return `${body.email} is already registered. You can login or reset your password via the Sunobi Sales iPad application.`
            }

            if (status === registrationStatuses.invalidRegistration) {
                return `Something about your registration link is incorrect. ${helpString}`
            }
        } else if (httpStatus === 404) {
            return `Something about your registration link is incorrect. ${helpString}`
        } else if (httpStatus === 500) {
            return `Sunobi servers returned an error. ${helpString}`
        }

        return `Please refresh the page. If you continue to experience issues then contact Sunobi Service via the help button.`
    }
}