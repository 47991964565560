const baseurl = 'https://integrations.sunobiapp.com/'
//const baseurl = "http://localhost:9090/"

const registrationStatuses = {
  alreadyRegistered: 1,
  canBeRegistered: 2,
  invalidRegistration: 3,
}

export { baseurl, registrationStatuses }
